exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/Contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-index-js": () => import("./../../../src/pages/LatestNews/index.js" /* webpackChunkName: "component---src-pages-latest-news-index-js" */),
  "component---src-pages-latest-news-news-detail-js": () => import("./../../../src/pages/LatestNews/newsDetail.js" /* webpackChunkName: "component---src-pages-latest-news-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/News/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-persone-index-js": () => import("./../../../src/pages/Persone/index.js" /* webpackChunkName: "component---src-pages-persone-index-js" */),
  "component---src-pages-press-singola-index-js": () => import("./../../../src/pages/PressSingola/index.js" /* webpackChunkName: "component---src-pages-press-singola-index-js" */),
  "component---src-pages-tenuta-index-js": () => import("./../../../src/pages/Tenuta/index.js" /* webpackChunkName: "component---src-pages-tenuta-index-js" */),
  "component---src-pages-terroir-index-js": () => import("./../../../src/pages/Terroir/index.js" /* webpackChunkName: "component---src-pages-terroir-index-js" */),
  "component---src-pages-vini-index-js": () => import("./../../../src/pages/Vini/index.js" /* webpackChunkName: "component---src-pages-vini-index-js" */),
  "component---src-pages-vini-product-page-index-js": () => import("./../../../src/pages/ViniProductPage/index.js" /* webpackChunkName: "component---src-pages-vini-product-page-index-js" */)
}

